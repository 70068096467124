import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import { graphql } from "gatsby";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Layout from "../components/Layout";
import PageBanner from "../components/PageBanner";
import Icon from "../components/Icon";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const CALENDAR_ID =
  "philipsacademync.org_mqpsd1h3huq6r7ls5db6gks3e4@group.calendar.google.com";
const API_KEY = "AIzaSyCZ2FWUnqVdw-qwPCUw8SzCR3stBy0SX7g";
const GOOGLE_CALENDAR_LINK =
  "https://calendar.google.com/calendar/embed?src=philipsacademync.org_mqpsd1h3huq6r7ls5db6gks3e4%40group.calendar.google.com&ctz=America%2FNew_York";
const HEIGHT = 500;

const Toolbar = ({ view, views, label, onNavigate, onView }) => {
  return (
    <div className="toolbar-container">
      <div className="toolbar-item">
        <button
          aria-label="previous"
          className="btn nav-btn"
          title="Previous"
          onClick={() => onNavigate("PREV")}
        >
          <Icon iconName="chevronDoubleLeft" width={16} />
        </button>
        <button
          aria-label="next"
          className="btn nav-btn"
          title="Next"
          onClick={() => onNavigate("NEXT")}
        >
          <Icon iconName="chevronDoubleRight" width={16} />
        </button>
        <span className="label">{label}</span>
      </div>
      <div className="toolbar-item toolbar-btns-container">
        <div>
          {views.map((v) => {
            return (
              <button
                onClick={() => onView(v)}
                key={v}
                className={`btn option-btn${v === view ? " active" : ""}`}
              >
                {v.toUpperCase()}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const CalendarPageTemplate = ({ pdf }) => {
  const [events, setEvents] = React.useState(null);
  React.useEffect(() => {
    const timeMin = moment()
      .subtract(2, "months")
      .format();
    const url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}&timeMin=${timeMin}`;
    axios.get(url).then(({ data }) => {
      let newEvents = [];
      data.items.forEach((event) => {
        if (event.start && event.end) {
          const e = { title: event.summary };
          if (event.start.dateTime) {
            e.start = moment(
              event.start.dateTime,
              "YYYY-MM-DDTHH:mm:ssZ"
            ).toDate();
            e.end = moment(
              event.start.dateTime,
              "YYYY-MM-DDTHH:mm:ssZ"
            ).toDate();
          } else {
            e.start = moment(event.start.date, "YYYY-MM-DD").toDate();
            e.end = moment(event.start.date, "YYYY-MM-DD").toDate();
          }
          newEvents.push(e);
        }
      });
      setEvents(newEvents);
    });
  }, []);
  return (
    <>
      <PageBanner>
        <h1 className="page-banner-header">CALENDAR</h1>
      </PageBanner>
      <section className="layout-container">
        <div className="top-image-container bg-white">
          <div>
            {events ? (
              <Calendar
                style={{ height: HEIGHT, margin: "16px" }}
                events={events || []}
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                components={{
                  toolbar: Toolbar,
                }}
              />
            ) : (
              <div
                style={{
                  height: HEIGHT,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Loading...</span>
              </div>
            )}
          </div>
          <div className="calendar-btn-container">
            {pdf && (
              <a
                href={pdf.publicURL}
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon iconName="pdf" width={18} />{" "}
                <span>View School Year Calendar</span>
              </a>
            )}
            <a
              href={GOOGLE_CALENDAR_LINK}
              className="btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon iconName="calendar" width={18} />{" "}
              <span>View Google Calendar</span>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

CalendarPageTemplate.propTypes = {
  pdf: PropTypes.object,
};

const CalendarPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { pdf },
    },
  } = data;
  return (
    <Layout
      pageTitle={`Calendar`}
      ogUrl="/calendar/"
      pageDecription="Stay up-to-date on the latest events happening at Philips Academy"
    >
      <CalendarPageTemplate pdf={pdf} />
    </Layout>
  );
};

CalendarPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CalendarPage;

export const CalendarPageQuery = graphql`
  query CalendarPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pdf {
          publicURL
        }
      }
    }
  }
`;
